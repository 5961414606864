import {Link} from "gatsby"
import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {getAndSetLang, isLangEng, isLangIt} from "../utils/language"


const IndexPage = () => {
  let lang = getAndSetLang("it")

  let content
  let title


  if( isLangIt() ){
      title="Festa"
      content =
      <>
        <p>La festa avrà luogo al <Link to="https://goo.gl/maps/YnekYrxvhySBvriS9">LAB in via mazzini</Link> alle 22:00. Ci sarà un drink
        a testa (quindi venite a stomaco pieno perfavore), lo spumante e la torta. Purtoppo siamo in crisi, perché io vi volevo offrire
        tutta la cena proprio. 🥹
        </p>
        <p>Nel frattempo, se non l'avete già fatto (o avete cambiato idea) compilate <Link to="https://forms.gle/LLzzbWoRUuENvRs6A">questo form</Link>
        (se è la seconda volta, specificatelo nelle note finali). Grazie 😘 </p>
      </>
  }
  if( isLangEng() ){
    title="Party"
    content = 
      <>
        <p>The party will be in <Link to="https://goo.gl/maps/YnekYrxvhySBvriS9">LAB via mazzini</Link> at 10:00 PM (22:00).
        There will be drink per person (please, don't come with empty stomach), sparkling wine and a cake. Unlickily, there's a big crysis going on,
        otherwise I would have offered a complete dinner 🥹
        </p>
        <p>In the meantime, if you didn't do it yet (or changed your mind) please answer a <Link to="https://forms.gle/jbtQPTvL67PDsLLq6">few questions (https://forms.gle/jbtQPTvL67PDsLLq6)</Link>
          (if it's the second time, write it in the final notes). Thank you 😘
        </p>
      </>
  }

  return (
    <Layout pageTitle={title} lang={lang}>
      {content}
    </Layout>
  )
}

export default IndexPage

export const Head = () => <Seo title={"Festa"} />
